"use strict";

// var React =  require('react');
import React from "react";
// var Slider =  require('react-slick').default;
import Slider from "react-slick";
// var ThreeBounce =  require('better-react-spinkit');
import ThreeBounce from "better-react-spinkit";
// var generalUtil = require("../general/generalUtil");
import generalUtil from "../general/generalUtil";
// import LazyLoad from 'react-lazyload';
// var axios = require("axios");
import axios from "axios";
// var querystring = require("querystring");
import querystring from "querystring";
if (typeof window === "undefined") {
  global.window = {};
}

const loaderStyle = {
  color: "#4a266e",
};
class ContentJS extends React.Component {
  constructor(props) {
    super(props);

    let settings = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 6,
      variableWidth: true,
    };

    let settingsBz = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      centerMode: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let settingsRE = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 6,
      variableWidth: true,
    };

    if (typeof window != "undefined") {
      settingsRE = {
        className: "slider variable-width",
        dots: true,
        infinite: false,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 3,
      };
    }
    let settingsTE = {
      dots: true,
      dotsClass: "testimonialslickdots",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    this.state = {
      otherProps: this.props.otherProps,
      album: [
        {
          url: "real-events/sneha-and-saagars-wedding",
          img: "asset/images/realevents16-1_new-min.jpg",
          altImg: "album3",
          eventName: "Sneha And Sagar Wedding",
          desc: "Bright colours, beautiful landscapes and people in love that was all there was to see in ..",
        },
        {
          url: "real-events/niddys-baby-shower",
          img: "asset/images/realevents_12_new-min.jpg",
          altImg: "album3",
          eventName: "Niddy's baby shower",
          desc: "A baby shower is the way to celebrate the upcoming birth of a child. It is a way to signif..",
        },
        {
          url: "real-events/vinamra-and-asmitas-wedding",
          img: "asset/images/realevents15-1-1_new-min.jpg",
          altImg: "album3",
          eventName: "Vinamra And Asmitas Wedding",
          desc: "Vinamra and Ashmita are two beautiful people who knew what love was the moment they..",
        },
        {
          url: "real-events/arivs-first-birthday-party",
          img: "asset/images/Ariv's_Birthday_new-min.jpg",
          altImg: "album3",
          eventName: "Ariv's First Birthday Party",
          desc: "A child is like a little droplet of sunshine on the face of earth. Little Ariv on his firs..",
        },
        {
          url: "real-events/garvikas-first-birthday-party",
          img: "asset/images/realevents_11_new-min.jpg",
          altImg: "album3",
          eventName: "Garvika's first birthday party",
          desc: "Garvika celebrated her first birthday with a whole lot of family, friends and cakes. The c..",
        },
        {
          url: "real-events/gordon-and-charlines-wedding",
          img: "asset/images/realevents_13_new-min.jpg",
          altImg: "album3",
          eventName: "Gordon And Charline's Wedding",
          desc: "These two souls came in union to commemorate their love and unite their promises in the pr..",
        },
        {
          url: "real-events/dil-dhadakne-do--a-cruise-party",
          img: "asset/images/realevents_14_new-min.jpg",
          altImg: "album3",
          eventName: "Dil Dhadakne Do - A Cruise Party",
          desc: "A Extravagant Cruise party that was quite the Heart-Throb of the Season!..",
        },
        {
          url: "real-events/nanki-and-ishaan-wedding",
          img: "asset/images/realevents_10_new-min.jpg",
          altImg: "album3",
          eventName: "Nanki And Ishaan Wedding",
          desc: "Nanki and Ishaan # Ishanki are already couple goals and their wedding was also in style! T..",
        },
      ],
      settingsRE,
      settings,
      settingsTE,
      settingsBz,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ otherProps: nextProps.otherProps });
  }
  componentWillMount() {
    var that = this;

    this.getTestimonials();
  }
  // onCitySelect(cityUniqueLink) {
  //   let banquetUrl =
  //     this.props.props.WEBSITE_BASE + "banquets/" + cityUniqueLink;

  //   if (cityUniqueLink == "destination-wedding") {
  //     banquetUrl = this.props.props.WEBSITE_BASE + cityUniqueLink;
  //   }
  //   window.open(banquetUrl, "_blank");
  // }
  componentDidMount() {
    var blazy = new Blazy();
    /* var that = this;
        axios.post(this.props.props.WEBSITE_BASE+"search/getCity")
            .then((xhrResponse) => {
                if(generalUtil.safeReturn(xhrResponse, 'data', false) !== false)
                {
                    let data = xhrResponse.data;
                    console.log(data);
                    that.setState({cityList:data});

                }
            })
            .catch((error) => {
                console.log(error);
            })*/
  }
  getTestimonials() {
    axios
      .post(
        "https://api.bookeventz.com/testimonials/fetchTestimonials",
        querystring.stringify({
          pageType: "home",
        })
      )
      .then((xhrResponse) => {
        if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
          let data = xhrResponse.data;

          this.setState({
            testimonaildata: data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    let loader = (
      <div className="testimonialsLoader loader-center">
        <ThreeBounce {...loaderStyle} />
      </div>
    );

    var sequenced = [];
    var normalarray = [];

    if (
      this.state.otherProps != undefined &&
      this.state.otherProps.cityList != undefined &&
      this.state.otherProps.cityList.length > 0
    ) {
      this.state.otherProps.cityList.map((row, i) => {
        if (row.sequence != 0) {
          sequenced.push(row);
        } else {
          normalarray.push(row);
        }
      });
      sequenced.sort(function (a, b) {
        return a.sequence - b.sequence;
      });
      normalarray.sort(function (a, b) {
        var x = a.CityName.toLowerCase();
        var y = b.CityName.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      var finalArray = [];
      sequenced.map((row) => {
        finalArray.push(row);
      });
      normalarray.map((row) => {
        finalArray.push(row);
      });
    }
    var that = this;
    return (
      <div>
        <div>
          {window.innerWidth > 768 ? (
            <section className="container-fluid preCitiesLocation">
              <div className="row title">
                <div className="col-xs-12">
                  <h2>Cities We Are Present In</h2>
                  <p className="hidden-xs text-center">10 Countries, 190 Cities</p>
                </div>
              </div>
              <div className="row body">
                <div id="CitySectionWrap" className="col-xs-12">
                  <div id="CitySection" className="citiesCarousel">
                    <Slider {...this.state.settings}>
                      {finalArray != undefined
                        ? finalArray.map((row, i) => {
                          if (row?.CountryId == that.props.sessionData?.CountryId && row.fileName) {
                            return (
                              <div key={i}>
                                <div className="items">
                                  <a
                                    title={"banquet halls in " + row.CityName}
                                    href={"https://www.bookeventz.com/" + row.CityUniqueLink}
                                    // onClick={this.onCitySelect.bind(
                                    //   this,
                                    //   row.CityUniqueLink
                                    // )}
                                    target={"_blank"}
                                  >
                                    <div className="zoom-container">
                                      <div className="img-overlay">
                                        <div className="layer"></div>
                                        <img
                                          className="b-lazy"
                                          width="100%"
                                          title={"banquet halls in " + row.CityName}
                                          alt={"Venue booking website for " + row.CityName}
                                          src={
                                            "https://media.bookeventz.com/html/bookeventz.com/images/cityPictures/" +
                                            row.fileName
                                          }
                                          data-src={
                                            "https://media.bookeventz.com/html/bookeventz.com/images/cityPictures/" +
                                            row.fileName
                                          }
                                        />
                                      </div>
                                    </div>
                                    <p className="cityName">{row.CityName}</p>
                                  </a>
                                </div>
                              </div>
                            );
                          }
                        }, this)
                        : ""}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}

          {this.props.sessionData != undefined &&
            (this.props.sessionData.CityId == "1" ||
              this.props.sessionData.CityId == "4" ||
              this.props.sessionData.CityId == "5" ||
              this.props.sessionData.CityId == "7" ||
              this.props.sessionData.CityId == "6" ||
              this.props.sessionData.CityId == "2" ||
              this.props.sessionData.CityId == "3" ||
              this.props.sessionData.CityId == "12" ||
              this.props.sessionData.CityId == "15" ||
              this.props.sessionData.CityId == "13" ||
              this.props.sessionData.CityId == "53" ||
              this.props.sessionData.CityId == "256") ? (
            <div className="container-fluid finestVenueSection">
              <h2 className="col-sm-12 category">Bookeventz Services</h2>
              <div
                className="row"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 15px",
                }}
              >
                <img
                  className="img-responsive availImg center-block text-center"
                  alt={"media.bookeventz.com"}
                  src="../../media/BookeventzServices.svg"
                ></img>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  {this.props.sessionData != undefined &&
            (this.props.sessionData.CityId == "1") &&   <div className="col-sm-6">
            <div className="row flagshipVenues">
              <div className="col-sm-6 col-xs-5">
                <div className="head">
                  <a
                    className=""
                    href={`https://www.bookeventz.com/banquets/bookeventz-venues/${this.props.sessionData?.CityUniqueLink}`}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    BookEventz Flagship
                  </a>
                </div>

                <div className="fullyManaged">Fully managed venues</div>
              </div>
              <div className="col-sm-6 col-xs-7 no-pad">
                {/* <LazyLoad height={100}> */}
                <img
                  className="img-responsive"
                  alt={"media.bookeventz.com"}
                  src="//media.bookeventz.com/html/bookeventz.com/asset/images/flagship_1.png"
                ></img>
                {/* </LazyLoad> */}
              </div>
            </div>
          </div>}
               
                  <div className="col-sm-6 no-pad">
                    <div className="row venuesWrap">
                      <div className="col-sm-6 col-xs-5">
                        <div className="head">
                          <a
                            href={
                              "https://www.bookeventz.com/banquets/" +
                              this.props.sessionData.CityUniqueLink
                            }
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                            target="_blank"

                          >
                            Venue
                          </a>
                        </div>
                        <div className="fullyManaged">Banquet halls, Lawns/Farmhouse, R..</div>
                      </div>
                      <div className="col-sm-6 col-xs-7 no-pad">
                        {/* <LazyLoad height={100}> */}
                        <img
                          className="img-responsive rightSectionImage"
                          alt={"media.bookeventz.com"}
                          src="//media.bookeventz.com/html/bookeventz.com/asset/images/venue_1.png"
                        ></img>
                        {/* </LazyLoad> */}
                      </div>
                    </div>
                  </div>


                  <div className="col-sm-6">
                    <div className="row photographerWrap">
                      <div className="col-sm-6 col-xs-5">
                        <div className="head">
                          <a
                            className=""
                            href={
                              "https://www.bookeventz.com/photographers/" +
                              this.props.sessionData.CityUniqueLink
                            }
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                            target="_blank"

                          >
                            Photographers
                          </a>
                        </div>
                        <div className="fullyManaged">
                          Photographers, Cinema/Video, Pre-wedding..
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-7 no-pad">
                        {/* <LazyLoad height={100}> */}
                        <img
                          className="img-responsive  text-right"
                          alt={"media.bookeventz.com"}
                          src="//media.bookeventz.com/html/bookeventz.com/asset/images/photographer_1.png"
                        ></img>
                        {/* </LazyLoad> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 no-pad">
                    <div className="row makeupWrap">
                      <div className="col-sm-6 col-xs-5">
                        <div className="head">
                          <a
                            className=""
                            href={
                              "https://www.bookeventz.com/bridal-makeups/" +
                              this.props.sessionData.CityUniqueLink
                            }
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                            target="_blank"

                          >
                            Makeup
                          </a>
                        </div>
                        <div className="fullyManaged">Bridal Makeup, Family Makeup</div>
                      </div>
                      <div className="col-sm-6 col-xs-7 no-pad">
                        {/* <LazyLoad height={100}> */}
                        <img
                          className="img-responsive  text-right"
                          alt={"media.bookeventz.com"}
                          src="//media.bookeventz.com/html/bookeventz.com/asset/images/makeup_1.png"
                        ></img>
                        {/* </LazyLoad> */}
                      </div>
                    </div>
                  </div>


                  <div className="col-sm-6">
                    <div className="row mehendiWrap">
                      <div className="col-sm-6 col-xs-5">
                        <div className="head">
                          <a
                            className=""
                            href={
                              "https://www.bookeventz.com/mehendi-artists/" +
                              this.props.sessionData.CityUniqueLink
                            }
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                            target="_blank"

                          >
                            Mehendi
                          </a>
                        </div>
                        <div className="fullyManaged">Bridal Mehendi</div>
                      </div>
                      <div className="col-sm-6 col-xs-7 no-pad">
                        {/* <LazyLoad height={100}> */}
                        <img
                          className="img-responsive text-right"
                          alt={"media.bookeventz.com"}
                          src="//media.bookeventz.com/html/bookeventz.com/asset/images/mehndi_1.png"
                        ></img>
                        {/* </LazyLoad> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 no-pad">
                    <div className="row decoratorWrap">
                      <div className="col-sm-6 col-xs-5">
                        <div className="head">
                          <a
                            className=""
                            href={`https://www.bookeventz.com/decorators/${this.props.sessionData?.CityUniqueLink}`}
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                            target="_blank"

                          >
                            Decorators
                          </a>
                        </div>
                        <div></div>
                      </div>
                      <div className="col-sm-6 col-xs-7 no-pad">
                        {/* <LazyLoad height={100}> */}
                        <img
                          className="img-responsive text-right"
                          alt={"media.bookeventz.com"}
                          src="//media.bookeventz.com/html/bookeventz.com/asset/images/Decorator_1.png"
                        ></img>
                        {/* </LazyLoad> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

// module.exports = ContentJS;
export default ContentJS;
