// var React = require('react');
var localStorageUtil = require("./localstorage");
// import $ from 'jquery';

module.exports = {
  unique: function (xs) {
    return xs.filter(function (x, i) {
      return xs.indexOf(x) === i;
    });
  },
  myGet: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    /*return decodeURIComponent(results[2].replace(/\+/g, " "));*/
    return decodeURIComponent(results[2]);
  },
  timeTO24hr: function (time) {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];

    if (AMPM == "pm") {
      if (hours == 12) {
        var hoursNew = 12;
      } else {
        var hoursNew = Number(hours) + Number(12);
      }
    }

    if (AMPM == "am") {
      if (hours == 12) {
        var hoursNew = 0;
      } else {
        var hoursNew = Number(hours);
      }
    }

    var sHours = hoursNew.toString();
    var sMinutes = minutes.toString();

    if (hoursNew < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;

    return sHours + ":" + sMinutes;
  },
  formatAMPM: function (date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },
  time24To12hr: function (time) {
    var hh = Number(time.match(/^(\d+)/)[1]);
    var m = Number(time.match(/:(\d+)/)[1]);
    var dd = "am";
    var h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "pm";
    }
    if (h == 0) {
      h = 12;
    }
    m = m < 10 ? "0" + m : m;

    var replacement = h + ":" + m;
    /* if you want to add seconds
         replacement += ":"+s;  */
    replacement += " " + dd;

    return replacement;
  },
  getPostPrams: function (element) {
    var post = $(element)
      .serializeArray()
      .filter(function (k) {
        return $.trim(k.value) != "";
      });
    post = $.parseParams($.param(post));
    /*var post = {};
         $.each($(element).serializeArray(), function(i, field)
         {
         post[field.name] = field.value;
         });*/

    return post;
  },
  userValidation: function (post) {
    var mandaField = 0;
    var data = {};
    for (let key in post) {
      let value = post[key];
      if (value != "") {
        switch (key) {
          case "userName":
            data[key] = value;
            mandaField++;
            break;

          case "userMobileNo":
            data[key] = value;
            if (post["countryCode"] == "+91") {
              if (typeof value != "undefined" && value.length == 10) {
                mandaField++;
              }
            } else {
              mandaField++;
            }
            break;

          case "userEmail":
            data[key] = value;
            mandaField++;
            break;
        }
      }
    }
    if (mandaField != 3) {
      data = {};
      data["error"] = "1";
      data["errorMsg"] = "User Details Not Provided";
    }

    return data;
  },
  reTrigger: function () {
    var formId = localStorageUtil.getSession("triggerSubmit");

    hideCustomLoader();
    if (formId != "") {
      /*console.log(formId);*/
      var temp = formId.split("|");
      if (temp.length == 2) {
        if (typeof temp[1] !== "undefined" && temp[1] == "reactForm") {
          $(document).trigger(temp[0] + "-trigger", data);
        }
      } else {
        $("#" + formId).trigger("submit");
      }
    }
    localStorageUtil.delSession("triggerSubmit");
  },

  inArray: function (needle, stack = []) {
    var ret = false;
    if (typeof needle != "undefined") {
      var i = stack.indexOf(needle);
      if (i > -1) {
        ret = true;
      }
    }

    return ret;
  },
  dateFormat: function (str) {
    if (str == null) {
      str = "";
    }
    var m_names = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var d = new Date(str.toString().replace(/-/g, "/"));
    if (d != "Invalid Date") {
      var curr_date = d.getDate();
      var curr_month = d.getMonth();
      var curr_year = d.getFullYear();

      var result = curr_date + " " + m_names[curr_month] + " " + curr_year;
      if (result == "1 Jan 1970") {
        result = "";
      }
    } else {
      result = "";
    }

    return result;
  },
  dateFormatYear: function (str) {
    if (str == null) {
      str = "";
    }
    var m_names = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var d = new Date(str.toString().replace(/-/g, "/"));
    if (d != "Invalid Date") {
      var curr_date = d.getDate();
      var curr_month = d.getMonth();
      var curr_year = d.getFullYear();

      var temp_year = curr_year.toString();
      var final_year = temp_year.substr(2, 3);
      var result = curr_date + " " + m_names[curr_month] + " " + final_year;
      if (result == "1 Jan 1970") {
        result = "";
      }
    } else {
      result = "";
    }

    return result;
  },
  formatDateToSql: function (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  priceFormat: function (no) {
    if (no == null || typeof no === "undefined" || no == "") {
      no = 0;
    }
    var _number = no.toString();
    var decimal = "";
    if (_number.indexOf(".") > -1) {
      decimal = _number.slice(_number.indexOf("."));
      _number = _number.slice(0, _number.indexOf("."));
    }

    if (_number > 0) {
      var _sep = ",";
      _number = typeof _number != "undefined" && _number > 0 ? _number : "";
      _number = _number
        .replace(
          new RegExp("^(\\d{" + (_number.length % 3 ? _number.length % 3 : 0) + "})(\\d{3})", "g"),
          "$1 $2"
        )
        .replace(/(\d{3})+?/gi, "$1 ")
        .trim();
      if (typeof _sep != "undefined" && _sep != " ") {
        _number = _number.replace(/\s/g, _sep);
      }
    }
    return _number + decimal;
  },
  isArray: function (obj) {
    var ret = false;
    if (obj.constructor === Array && obj.length > 0) {
      ret = true;
    }
    return ret;
  },
  shuffle: function (array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  },
  getImageDimensions: function (url) {
    var img = new Image();
    img.addEventListener("load", function () {
      var val = {
        width: this.naturalWidth,
        height: this.naturalHeight,
      };
      return val;
    });
    img.src = url;
  },
  createBanquetUrl: function (props, occasionId, areaId, budget, capacity) {
    var url = props.base_url + "banquets/" + props.sessionData.cityUniqueLink;

    var occasion = "";
    if (typeof occasionId !== "undefined" && occasionId != "" && occasionId != 0) {
      if (typeof props.occasionList != "undefined" && props.occasionList != null) {
        for (let i in props.occasionList) {
          if (props.occasionList[i].id == occasionId) {
            occasion = "/" + props.occasionList[i].uniqueLink;
          }
        }
      }
    }
    var area = "";
    if (typeof areaId !== "undefined" && areaId != "" && areaId != 0) {
      if (typeof props.areaList != "undefined" && props.areaList != null) {
        for (let i in props.areaList) {
          if (props.areaList[i].AreaId == areaId) {
            area = "/" + props.areaList[i].AreaUniquelink;
          }
        }
      }
    }

    var queryString = [];
    if (typeof budget !== "undefined" && budget != "") {
      queryString[0] = "budget-range=" + budget;
    }
    if (typeof capacity !== "undefined" && capacity != "") {
      queryString[1] = "capacity-range=" + capacity;
    }

    var queryStringNew = "";
    if (queryString.length > 0) {
      queryStringNew = "?" + queryString.join("&");
    }

    url = url + area + occasion + queryStringNew;

    return url;
  },
  modifyStr: function (str) {
    if (str != "") {
      str = str.toLowerCase();
      str = str.replace(/  /g, " ");
      str = str.replace(/[/]/g, "");
      str = str.replace(/ /g, "_");
      str = str.replace(/__/g, "_");
    }
    return str;
  },
  myVarDump: function (value) { },
  countryCodeList: function () {
    var countryCode = [
      {
        name: "Afghanistan",
        dial_code: "+93",
        code: "AF",
      },
      {
        name: "Aland Islands",
        dial_code: "+358",
        code: "AX",
      },
      {
        name: "Albania",
        dial_code: "+355",
        code: "AL",
      },
      {
        name: "Algeria",
        dial_code: "+213",
        code: "DZ",
      },
      {
        name: "AmericanSamoa",
        dial_code: "+1684",
        code: "AS",
      },
      {
        name: "Andorra",
        dial_code: "+376",
        code: "AD",
      },
      {
        name: "Angola",
        dial_code: "+244",
        code: "AO",
      },
      {
        name: "Anguilla",
        dial_code: "+1264",
        code: "AI",
      },
      {
        name: "Antarctica",
        dial_code: "+672",
        code: "AQ",
      },
      {
        name: "Antigua and Barbuda",
        dial_code: "+1268",
        code: "AG",
      },
      {
        name: "Argentina",
        dial_code: "+54",
        code: "AR",
      },
      {
        name: "Armenia",
        dial_code: "+374",
        code: "AM",
      },
      {
        name: "Aruba",
        dial_code: "+297",
        code: "AW",
      },
      {
        name: "Australia",
        dial_code: "+61",
        code: "AU",
      },
      {
        name: "Austria",
        dial_code: "+43",
        code: "AT",
      },
      {
        name: "Azerbaijan",
        dial_code: "+994",
        code: "AZ",
      },
      {
        name: "Bahamas",
        dial_code: "+1242",
        code: "BS",
      },
      {
        name: "Bahrain",
        dial_code: "+973",
        code: "BH",
      },
      {
        name: "Bangladesh",
        dial_code: "+880",
        code: "BD",
      },
      {
        name: "Barbados",
        dial_code: "+1246",
        code: "BB",
      },
      {
        name: "Belarus",
        dial_code: "+375",
        code: "BY",
      },
      {
        name: "Belgium",
        dial_code: "+32",
        code: "BE",
      },
      {
        name: "Belize",
        dial_code: "+501",
        code: "BZ",
      },
      {
        name: "Benin",
        dial_code: "+229",
        code: "BJ",
      },
      {
        name: "Bermuda",
        dial_code: "+1441",
        code: "BM",
      },
      {
        name: "Bhutan",
        dial_code: "+975",
        code: "BT",
      },
      {
        name: "Bolivia, Plurinational State of",
        dial_code: "+591",
        code: "BO",
      },
      {
        name: "Bosnia and Herzegovina",
        dial_code: "+387",
        code: "BA",
      },
      {
        name: "Botswana",
        dial_code: "+267",
        code: "BW",
      },
      {
        name: "Brazil",
        dial_code: "+55",
        code: "BR",
      },
      {
        name: "British Indian Ocean Territory",
        dial_code: "+246",
        code: "IO",
      },
      {
        name: "Brunei Darussalam",
        dial_code: "+673",
        code: "BN",
      },
      {
        name: "Bulgaria",
        dial_code: "+359",
        code: "BG",
      },
      {
        name: "Burkina Faso",
        dial_code: "+226",
        code: "BF",
      },
      {
        name: "Burundi",
        dial_code: "+257",
        code: "BI",
      },
      {
        name: "Cambodia",
        dial_code: "+855",
        code: "KH",
      },
      {
        name: "Cameroon",
        dial_code: "+237",
        code: "CM",
      },
      {
        name: "Canada",
        dial_code: "+1",
        code: "CA",
      },
      {
        name: "Cape Verde",
        dial_code: "+238",
        code: "CV",
      },
      {
        name: "Cayman Islands",
        dial_code: "+ 345",
        code: "KY",
      },
      {
        name: "Central African Republic",
        dial_code: "+236",
        code: "CF",
      },
      {
        name: "Chad",
        dial_code: "+235",
        code: "TD",
      },
      {
        name: "Chile",
        dial_code: "+56",
        code: "CL",
      },
      {
        name: "China",
        dial_code: "+86",
        code: "CN",
      },
      {
        name: "Christmas Island",
        dial_code: "+61",
        code: "CX",
      },
      {
        name: "Cocos (Keeling) Islands",
        dial_code: "+61",
        code: "CC",
      },
      {
        name: "Colombia",
        dial_code: "+57",
        code: "CO",
      },
      {
        name: "Comoros",
        dial_code: "+269",
        code: "KM",
      },
      {
        name: "Congo",
        dial_code: "+242",
        code: "CG",
      },
      {
        name: "Congo, The Democratic Republic of the Congo",
        dial_code: "+243",
        code: "CD",
      },
      {
        name: "Cook Islands",
        dial_code: "+682",
        code: "CK",
      },
      {
        name: "Costa Rica",
        dial_code: "+506",
        code: "CR",
      },
      {
        name: "Cote d'Ivoire",
        dial_code: "+225",
        code: "CI",
      },
      {
        name: "Croatia",
        dial_code: "+385",
        code: "HR",
      },
      {
        name: "Cuba",
        dial_code: "+53",
        code: "CU",
      },
      {
        name: "Cyprus",
        dial_code: "+357",
        code: "CY",
      },
      {
        name: "Czech Republic",
        dial_code: "+420",
        code: "CZ",
      },
      {
        name: "Denmark",
        dial_code: "+45",
        code: "DK",
      },
      {
        name: "Djibouti",
        dial_code: "+253",
        code: "DJ",
      },
      {
        name: "Dominica",
        dial_code: "+1767",
        code: "DM",
      },
      {
        name: "Dominican Republic",
        dial_code: "+1849",
        code: "DO",
      },
      {
        name: "Ecuador",
        dial_code: "+593",
        code: "EC",
      },
      {
        name: "Egypt",
        dial_code: "+20",
        code: "EG",
      },
      {
        name: "El Salvador",
        dial_code: "+503",
        code: "SV",
      },
      {
        name: "Equatorial Guinea",
        dial_code: "+240",
        code: "GQ",
      },
      {
        name: "Eritrea",
        dial_code: "+291",
        code: "ER",
      },
      {
        name: "Estonia",
        dial_code: "+372",
        code: "EE",
      },
      {
        name: "Ethiopia",
        dial_code: "+251",
        code: "ET",
      },
      {
        name: "Falkland Islands (Malvinas)",
        dial_code: "+500",
        code: "FK",
      },
      {
        name: "Faroe Islands",
        dial_code: "+298",
        code: "FO",
      },
      {
        name: "Fiji",
        dial_code: "+679",
        code: "FJ",
      },
      {
        name: "Finland",
        dial_code: "+358",
        code: "FI",
      },
      {
        name: "France",
        dial_code: "+33",
        code: "FR",
      },
      {
        name: "French Guiana",
        dial_code: "+594",
        code: "GF",
      },
      {
        name: "French Polynesia",
        dial_code: "+689",
        code: "PF",
      },
      {
        name: "Gabon",
        dial_code: "+241",
        code: "GA",
      },
      {
        name: "Gambia",
        dial_code: "+220",
        code: "GM",
      },
      {
        name: "Georgia",
        dial_code: "+995",
        code: "GE",
      },
      {
        name: "Germany",
        dial_code: "+49",
        code: "DE",
      },
      {
        name: "Ghana",
        dial_code: "+233",
        code: "GH",
      },
      {
        name: "Gibraltar",
        dial_code: "+350",
        code: "GI",
      },
      {
        name: "Greece",
        dial_code: "+30",
        code: "GR",
      },
      {
        name: "Greenland",
        dial_code: "+299",
        code: "GL",
      },
      {
        name: "Grenada",
        dial_code: "+1473",
        code: "GD",
      },
      {
        name: "Guadeloupe",
        dial_code: "+590",
        code: "GP",
      },
      {
        name: "Guam",
        dial_code: "+1671",
        code: "GU",
      },
      {
        name: "Guatemala",
        dial_code: "+502",
        code: "GT",
      },
      {
        name: "Guernsey",
        dial_code: "+44",
        code: "GG",
      },
      {
        name: "Guinea",
        dial_code: "+224",
        code: "GN",
      },
      {
        name: "Guinea-Bissau",
        dial_code: "+245",
        code: "GW",
      },
      {
        name: "Guyana",
        dial_code: "+595",
        code: "GY",
      },
      {
        name: "Haiti",
        dial_code: "+509",
        code: "HT",
      },
      {
        name: "Holy See (Vatican City State)",
        dial_code: "+379",
        code: "VA",
      },
      {
        name: "Honduras",
        dial_code: "+504",
        code: "HN",
      },
      {
        name: "Hong Kong",
        dial_code: "+852",
        code: "HK",
      },
      {
        name: "Hungary",
        dial_code: "+36",
        code: "HU",
      },
      {
        name: "Iceland",
        dial_code: "+354",
        code: "IS",
      },
      {
        name: "India",
        dial_code: "+91",
        code: "IN",
      },
      {
        name: "Indonesia",
        dial_code: "+62",
        code: "ID",
      },
      {
        name: "Iran, Islamic Republic of Persian Gulf",
        dial_code: "+98",
        code: "IR",
      },
      {
        name: "Iraq",
        dial_code: "+964",
        code: "IQ",
      },
      {
        name: "Ireland",
        dial_code: "+353",
        code: "IE",
      },
      {
        name: "Isle of Man",
        dial_code: "+44",
        code: "IM",
      },
      {
        name: "Israel",
        dial_code: "+972",
        code: "IL",
      },
      {
        name: "Italy",
        dial_code: "+39",
        code: "IT",
      },
      {
        name: "Jamaica",
        dial_code: "+1876",
        code: "JM",
      },
      {
        name: "Japan",
        dial_code: "+81",
        code: "JP",
      },
      {
        name: "Jersey",
        dial_code: "+44",
        code: "JE",
      },
      {
        name: "Jordan",
        dial_code: "+962",
        code: "JO",
      },
      {
        name: "Kazakhstan",
        dial_code: "+77",
        code: "KZ",
      },
      {
        name: "Kenya",
        dial_code: "+254",
        code: "KE",
      },
      {
        name: "Kiribati",
        dial_code: "+686",
        code: "KI",
      },
      {
        name: "Korea, Democratic People's Republic of Korea",
        dial_code: "+850",
        code: "KP",
      },
      {
        name: "Korea, Republic of South Korea",
        dial_code: "+82",
        code: "KR",
      },
      {
        name: "Kuwait",
        dial_code: "+965",
        code: "KW",
      },
      {
        name: "Kyrgyzstan",
        dial_code: "+996",
        code: "KG",
      },
      {
        name: "Laos",
        dial_code: "+856",
        code: "LA",
      },
      {
        name: "Latvia",
        dial_code: "+371",
        code: "LV",
      },
      {
        name: "Lebanon",
        dial_code: "+961",
        code: "LB",
      },
      {
        name: "Lesotho",
        dial_code: "+266",
        code: "LS",
      },
      {
        name: "Liberia",
        dial_code: "+231",
        code: "LR",
      },
      {
        name: "Libyan Arab Jamahiriya",
        dial_code: "+218",
        code: "LY",
      },
      {
        name: "Liechtenstein",
        dial_code: "+423",
        code: "LI",
      },
      {
        name: "Lithuania",
        dial_code: "+370",
        code: "LT",
      },
      {
        name: "Luxembourg",
        dial_code: "+352",
        code: "LU",
      },
      {
        name: "Macao",
        dial_code: "+853",
        code: "MO",
      },
      {
        name: "Macedonia",
        dial_code: "+389",
        code: "MK",
      },
      {
        name: "Madagascar",
        dial_code: "+261",
        code: "MG",
      },
      {
        name: "Malawi",
        dial_code: "+265",
        code: "MW",
      },
      {
        name: "Malaysia",
        dial_code: "+60",
        code: "MY",
      },
      {
        name: "Maldives",
        dial_code: "+960",
        code: "MV",
      },
      {
        name: "Mali",
        dial_code: "+223",
        code: "ML",
      },
      {
        name: "Malta",
        dial_code: "+356",
        code: "MT",
      },
      {
        name: "Marshall Islands",
        dial_code: "+692",
        code: "MH",
      },
      {
        name: "Martinique",
        dial_code: "+596",
        code: "MQ",
      },
      {
        name: "Mauritania",
        dial_code: "+222",
        code: "MR",
      },
      {
        name: "Mauritius",
        dial_code: "+230",
        code: "MU",
      },
      {
        name: "Mayotte",
        dial_code: "+262",
        code: "YT",
      },
      {
        name: "Mexico",
        dial_code: "+52",
        code: "MX",
      },
      {
        name: "Micronesia, Federated States of Micronesia",
        dial_code: "+691",
        code: "FM",
      },
      {
        name: "Moldova",
        dial_code: "+373",
        code: "MD",
      },
      {
        name: "Monaco",
        dial_code: "+377",
        code: "MC",
      },
      {
        name: "Mongolia",
        dial_code: "+976",
        code: "MN",
      },
      {
        name: "Montenegro",
        dial_code: "+382",
        code: "ME",
      },
      {
        name: "Montserrat",
        dial_code: "+1664",
        code: "MS",
      },
      {
        name: "Morocco",
        dial_code: "+212",
        code: "MA",
      },
      {
        name: "Mozambique",
        dial_code: "+258",
        code: "MZ",
      },
      {
        name: "Myanmar",
        dial_code: "+95",
        code: "MM",
      },
      {
        name: "Namibia",
        dial_code: "+264",
        code: "NA",
      },
      {
        name: "Nauru",
        dial_code: "+674",
        code: "NR",
      },
      {
        name: "Nepal",
        dial_code: "+977",
        code: "NP",
      },
      {
        name: "Netherlands",
        dial_code: "+31",
        code: "NL",
      },
      {
        name: "Netherlands Antilles",
        dial_code: "+599",
        code: "AN",
      },
      {
        name: "New Caledonia",
        dial_code: "+687",
        code: "NC",
      },
      {
        name: "New Zealand",
        dial_code: "+64",
        code: "NZ",
      },
      {
        name: "Nicaragua",
        dial_code: "+505",
        code: "NI",
      },
      {
        name: "Niger",
        dial_code: "+227",
        code: "NE",
      },
      {
        name: "Nigeria",
        dial_code: "+234",
        code: "NG",
      },
      {
        name: "Niue",
        dial_code: "+683",
        code: "NU",
      },
      {
        name: "Norfolk Island",
        dial_code: "+672",
        code: "NF",
      },
      {
        name: "Northern Mariana Islands",
        dial_code: "+1670",
        code: "MP",
      },
      {
        name: "Norway",
        dial_code: "+47",
        code: "NO",
      },
      {
        name: "Oman",
        dial_code: "+968",
        code: "OM",
      },
      {
        name: "Pakistan",
        dial_code: "+92",
        code: "PK",
      },
      {
        name: "Palau",
        dial_code: "+680",
        code: "PW",
      },
      {
        name: "Palestinian Territory, Occupied",
        dial_code: "+970",
        code: "PS",
      },
      {
        name: "Panama",
        dial_code: "+507",
        code: "PA",
      },
      {
        name: "Papua New Guinea",
        dial_code: "+675",
        code: "PG",
      },
      {
        name: "Paraguay",
        dial_code: "+595",
        code: "PY",
      },
      {
        name: "Peru",
        dial_code: "+51",
        code: "PE",
      },
      {
        name: "Philippines",
        dial_code: "+63",
        code: "PH",
      },
      {
        name: "Pitcairn",
        dial_code: "+872",
        code: "PN",
      },
      {
        name: "Poland",
        dial_code: "+48",
        code: "PL",
      },
      {
        name: "Portugal",
        dial_code: "+351",
        code: "PT",
      },
      {
        name: "Puerto Rico",
        dial_code: "+1939",
        code: "PR",
      },
      {
        name: "Qatar",
        dial_code: "+974",
        code: "QA",
      },
      {
        name: "Romania",
        dial_code: "+40",
        code: "RO",
      },
      {
        name: "Russia",
        dial_code: "+7",
        code: "RU",
      },
      {
        name: "Rwanda",
        dial_code: "+250",
        code: "RW",
      },
      {
        name: "Reunion",
        dial_code: "+262",
        code: "RE",
      },
      {
        name: "Saint Barthelemy",
        dial_code: "+590",
        code: "BL",
      },
      {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        dial_code: "+290",
        code: "SH",
      },
      {
        name: "Saint Kitts and Nevis",
        dial_code: "+1869",
        code: "KN",
      },
      {
        name: "Saint Lucia",
        dial_code: "+1758",
        code: "LC",
      },
      {
        name: "Saint Martin",
        dial_code: "+590",
        code: "MF",
      },
      {
        name: "Saint Pierre and Miquelon",
        dial_code: "+508",
        code: "PM",
      },
      {
        name: "Saint Vincent and the Grenadines",
        dial_code: "+1784",
        code: "VC",
      },
      {
        name: "Samoa",
        dial_code: "+685",
        code: "WS",
      },
      {
        name: "San Marino",
        dial_code: "+378",
        code: "SM",
      },
      {
        name: "Sao Tome and Principe",
        dial_code: "+239",
        code: "ST",
      },
      {
        name: "Saudi Arabia",
        dial_code: "+966",
        code: "SA",
      },
      {
        name: "Senegal",
        dial_code: "+221",
        code: "SN",
      },
      {
        name: "Serbia",
        dial_code: "+381",
        code: "RS",
      },
      {
        name: "Seychelles",
        dial_code: "+248",
        code: "SC",
      },
      {
        name: "Sierra Leone",
        dial_code: "+232",
        code: "SL",
      },
      {
        name: "Singapore",
        dial_code: "+65",
        code: "SG",
      },
      {
        name: "Slovakia",
        dial_code: "+421",
        code: "SK",
      },
      {
        name: "Slovenia",
        dial_code: "+386",
        code: "SI",
      },
      {
        name: "Solomon Islands",
        dial_code: "+677",
        code: "SB",
      },
      {
        name: "Somalia",
        dial_code: "+252",
        code: "SO",
      },
      {
        name: "South Africa",
        dial_code: "+27",
        code: "ZA",
      },
      {
        name: "South Sudan",
        dial_code: "+211",
        code: "SS",
      },
      {
        name: "South Georgia and the South Sandwich Islands",
        dial_code: "+500",
        code: "GS",
      },
      {
        name: "Spain",
        dial_code: "+34",
        code: "ES",
      },
      {
        name: "Sri Lanka",
        dial_code: "+94",
        code: "LK",
      },
      {
        name: "Sudan",
        dial_code: "+249",
        code: "SD",
      },
      {
        name: "Suriname",
        dial_code: "+597",
        code: "SR",
      },
      {
        name: "Svalbard and Jan Mayen",
        dial_code: "+47",
        code: "SJ",
      },
      {
        name: "Swaziland",
        dial_code: "+268",
        code: "SZ",
      },
      {
        name: "Sweden",
        dial_code: "+46",
        code: "SE",
      },
      {
        name: "Switzerland",
        dial_code: "+41",
        code: "CH",
      },
      {
        name: "Syrian Arab Republic",
        dial_code: "+963",
        code: "SY",
      },
      {
        name: "Taiwan",
        dial_code: "+886",
        code: "TW",
      },
      {
        name: "Tajikistan",
        dial_code: "+992",
        code: "TJ",
      },
      {
        name: "Tanzania, United Republic of Tanzania",
        dial_code: "+255",
        code: "TZ",
      },
      {
        name: "Thailand",
        dial_code: "+66",
        code: "TH",
      },
      {
        name: "Timor-Leste",
        dial_code: "+670",
        code: "TL",
      },
      {
        name: "Togo",
        dial_code: "+228",
        code: "TG",
      },
      {
        name: "Tokelau",
        dial_code: "+690",
        code: "TK",
      },
      {
        name: "Tonga",
        dial_code: "+676",
        code: "TO",
      },
      {
        name: "Trinidad and Tobago",
        dial_code: "+1868",
        code: "TT",
      },
      {
        name: "Tunisia",
        dial_code: "+216",
        code: "TN",
      },
      {
        name: "Turkey",
        dial_code: "+90",
        code: "TR",
      },
      {
        name: "Turkmenistan",
        dial_code: "+993",
        code: "TM",
      },
      {
        name: "Turks and Caicos Islands",
        dial_code: "+1649",
        code: "TC",
      },
      {
        name: "Tuvalu",
        dial_code: "+688",
        code: "TV",
      },
      {
        name: "Uganda",
        dial_code: "+256",
        code: "UG",
      },
      {
        name: "Ukraine",
        dial_code: "+380",
        code: "UA",
      },
      {
        name: "United Arab Emirates",
        dial_code: "+971",
        code: "AE",
      },
      {
        name: "United Kingdom",
        dial_code: "+44",
        code: "GB",
      },
      {
        name: "United States",
        dial_code: "+1",
        code: "US",
      },
      {
        name: "Uruguay",
        dial_code: "+598",
        code: "UY",
      },
      {
        name: "Uzbekistan",
        dial_code: "+998",
        code: "UZ",
      },
      {
        name: "Vanuatu",
        dial_code: "+678",
        code: "VU",
      },
      {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        dial_code: "+58",
        code: "VE",
      },
      {
        name: "Vietnam",
        dial_code: "+84",
        code: "VN",
      },
      {
        name: "Virgin Islands, British",
        dial_code: "+1284",
        code: "VG",
      },
      {
        name: "Virgin Islands, U.S.",
        dial_code: "+1340",
        code: "VI",
      },
      {
        name: "Wallis and Futuna",
        dial_code: "+681",
        code: "WF",
      },
      {
        name: "Yemen",
        dial_code: "+967",
        code: "YE",
      },
      {
        name: "Zambia",
        dial_code: "+260",
        code: "ZM",
      },
      {
        name: "Zimbabwe",
        dial_code: "+263",
        code: "ZW",
      },
    ];

    var countryCodeNew = [];
    countryCode.forEach(function (row, i) {
      var count = Number(i) + Number(1);
      row.id = count.toString();

      row.defaultSelected = "0";
      if (row.id == "98") {
        row.defaultSelected = "1";
      }

      countryCodeNew.push(row);
    });

    return countryCodeNew;
  },
  requiredFieldValidation: function (params, errorMsgLog, inputNames, inputIndex, REQUIRED_ERROR) {
    if (
      typeof params[inputNames[inputIndex]] != "undefined" &&
      params[inputNames[inputIndex]] != ""
    ) {
      if (typeof errorMsgLog[inputNames[inputIndex]] != "undefined") {
        if (
          errorMsgLog[inputNames[inputIndex]] == REQUIRED_ERROR ||
          errorMsgLog[inputNames[inputIndex]] == ""
        ) {
          delete errorMsgLog[inputNames[inputIndex]];
        }
      }
    } else {
      if (typeof errorMsgLog[inputNames[inputIndex]] == "undefined") {
        errorMsgLog[inputNames[inputIndex]] = REQUIRED_ERROR;
      }
    }

    return errorMsgLog;
  },
  safeReturn: function (array = [], index, defaultValue, checkFor) {
    defaultValue = typeof defaultValue == "undefined" ? false : defaultValue;

    if (
      typeof array == "undefined" ||
      typeof index == "undefined" ||
      array == null ||
      index == null
    ) {
      return defaultValue;
    }
    if (typeof array !== "object" && typeof array[index] === "undefined") {
      return defaultValue;
    }
    /*if (!(index in array)) {
            return defaultValue;
        }*/
    if (this.inArray(null, checkFor) && array[index] == null) {
      return defaultValue;
    }
    if (this.inArray("", checkFor) && array[index] === "") {
      return defaultValue;
    }
    if (this.inArray("0", checkFor) && array[index] == "0") {
      return defaultValue;
    }
    return array[index];
  },
  formatAMPM: function (date1) {
    var date = new Date(date1);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },
  objectSize: function (obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  },
  callGa: function (category, action, lbl, enquiryValue, formType) {
    if (typeof callGa != "undefined") {
      var enquiryValueNew = "";
      if (typeof enquiryValue != "undefined" && enquiryValue != null) {
        enquiryValueNew = enquiryValue;
      }
      var formTypeNew = "";
      if (typeof formType != "undefined" && formType != null) {
        formTypeNew = formType;
      }
      var windowPageType = localStorage.getItem("pageType");

      var urlPathName = "";
      if (typeof window.location.pathname != "undefined") {
        urlPathName = window.WEB_BASE + window.location.pathname;
      }

      var deviceType = "desktop";
      if (window.innerWidth < 767) {
        deviceType = "mobile";
      }

      var lblNew = "";
      if (typeof lbl != "undefined" && lbl != null) {
        lblNew = lbl.toString();
        if (window.innerWidth < 767 && lblNew.indexOf("mobile") >= 0) {
          lblNew = lblNew + " mobile";
        }
      }

      var isAdmin = (function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        /*return decodeURIComponent(results[2].replace(/\+/g, " "));*/
        return decodeURIComponent(results[2]);
      })("isAdminAccessing");

      if (isAdmin) {
        return;
      }

      var pageTypeArr = [
        "home",
        "venueListing",
        "venueProduct",
        "catererListing",
        "catererProduct",
        "artistListing",
        "artistProduct",
        "userDashboard",
        "landingPageView",
        "contractorPackages",
      ];

      var check = (function (needle, stack) {
        var ret = false;
        if (typeof needle != "undefined") {
          var i = stack.indexOf(needle);
          if (i > -1) {
            ret = true;
          }
        }

        return ret;
      })(windowPageType, pageTypeArr);

      if (check) {
        if (action == "submit") {
          ga("send", "event", category, action, lblNew, {
            dimension1: enquiryValueNew,
            dimension2: windowPageType,
            dimension3: urlPathName,
            dimension4: formTypeNew,
            dimension5: deviceType,
          });
        } else {
          if (action == "Page Load" || action == "loaded") {
            ga("send", "event", category, action, lblNew, { nonInteraction: 1 });
          } else {
            ga("send", "event", category, action, lblNew);
          }
        }
      }
    }
  },
  convertToAmount: function (yourNumber) {
    var components = yourNumber.toString().split(".");
    //Comma-fies the first part
    components[0] = components[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //Combines the two sections
    return components.join(".");
  },
  validateEmail: function (email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  offset: function (el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft,
      viewPortTop: rect.top,
    };
  },
  roundUp: function (to, x) {
    x = parseInt(x);
    return Math.ceil(x / to) * to;
  },
  getOrdinal: function (date) {
    if (typeof date == "undefined") {
      return "";
    }
    if (date > 3 && date < 21) return date + "th";
    switch (date % 10) {
      case 1:
        return date + "st";
      case 2:
        return date + "nd";
      case 3:
        return date + "rd";
      default:
        return date + "th";
    }
  },
  callGaDashboard: function (category, action, lbl, enquiryValue, formType) {
    // console.log('Inside callGa func',typeof callGaDashboard != 'undefined')
    // if(typeof callGaDashboard != 'undefined')
    // {
    var enquiryValueNew = "";
    if (typeof enquiryValue != "undefined" && enquiryValue != null) {
      enquiryValueNew = enquiryValue;
    }
    var formTypeNew = "";
    if (typeof formType != "undefined" && formType != null) {
      formTypeNew = formType;
    }
    if (typeof window !== "undefined" && window.localStorage) {
      var windowPageType = localStorage.getItem("pageType");
    }

    var urlPathName = "";
    if (typeof window.location.pathname != "undefined") {
      urlPathName = window.WEB_BASE + window.location.pathname;
    }

    var deviceType = "desktop";
    if (window.innerWidth < 767) {
      deviceType = "mobile";
    }

    var lblNew = "";
    if (typeof lbl != "undefined" && lbl != null) {
      lblNew = lbl.toString();
      if (window.innerWidth < 767 && lblNew.indexOf("mobile") >= 0) {
        lblNew = lblNew + " mobile";
      }
    }

    var isAdmin = (function (name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      /*return decodeURIComponent(results[2].replace(/\+/g, " "));*/
      return decodeURIComponent(results[2]);
    })("isAdminAccessing");

    if (isAdmin) {
      return;
    }

    var pageTypeArr = [
      "home",
      "venueListing",
      "venueProduct",
      "catererListing",
      "catererProduct",
      "artistListing",
      "artistProduct",
      "userDashboard",
      "landingPageView",
      "contractorPackages",
      "offersPage",
    ];

    var check = (function (needle, stack) {
      var ret = false;
      if (typeof needle != "undefined") {
        var i = stack.indexOf(needle);
        if (i > -1) {
          ret = true;
        }
      }

      return ret;
    })(windowPageType, pageTypeArr);
    if (check) {
      if (action == "submit") {
        ga("send", "event", category, action, lblNew, {
          dimension1: enquiryValueNew,
          dimension2: windowPageType,
          dimension3: urlPathName,
          dimension4: formTypeNew,
          dimension5: deviceType,
        });
      } else {
        if (action == "Page Load" || action == "loaded") {
          ga("send", "event", category, action, lblNew, { nonInteraction: 1 });
        } else {
          ga("send", "event", category, action, lblNew);
        }
      }
    }
    // }
  },
  fcmPushNotification: function (messaging, onMessage, getToken) {
    // if(typeof fcmPushNotification != 'undefined')
    // {
    // console.log(messaging,'messaging in fcmPushNotification')
    // async()=>{
    let key =
      "BIpe-lHaNBQSMHtSQf1lOuJyscP9cui9FaS2YvcFh6YfaZxwCkWCjZxlzMX_Xy4XVoC8kXx1i4CAiQ064RDikug"; //vapidKey
    // const serviceWorkerRegistration = await  navigator.serviceWorker.register('https://gruntssr.bookeventz.com/firebase-messaging-service-worker.js');
    // console.log(serviceWorkerRegistration,'serviceWorkerRegistration');
    // const registration = await navigator.serviceWorker.ready;
    // console.log(registration,'registration')
    getToken(messaging, { vapidKey: key })
      .then((currentToken) => {
        if (currentToken) {
          // console.log(currentToken,'currentToken.....')
          // if(!localStorage.getItem('fcmToken')){
          localStorage.setItem("fcmToken", currentToken);
          // }
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          // console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token... ", err);
        // ...
      });
    // console.log(messaging,'app');
    // }

    // onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload);
    //     let permission = Notification.permission;
    //     console.log(permission,'permission')
    //     if(permission === "granted") {
    //         // showNotification();
    //         if(document.visibilityState === "visible") {
    //             // return;
    //         }
    //         var title = payload.notification.title;
    //         let icon = "https://img.onesignal.com/t/6574a703-52ff-45cc-bbfb-a941769222db.png"
    //         var body = payload.notification.body;
    //         var notification = new Notification(title, { body, icon });
    //         notification.onclick = () => {
    //                notification.close();
    //                window.parent.focus();
    //         }
    //     } else if(permission === "default"){
    //         // requestAndShowPermission();
    //     } else {
    //         console.log("Use normal alert");
    //     }
    // });
    // }
  },
  uniqueUrlLinkCheck: function (urls) {
    let urlArray = urls.split("/");
    let removeDuplicate = urlArray.filter((item, index) => urlArray.indexOf(item) === index);
    return removeDuplicate.join("/");
  },
};
