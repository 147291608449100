import React from "react";
import {render} from "react-dom";
import Landing404App from "../views/Landing404App";
import config from "../config/config";
import HeaderLoginSection from "../views/general/headerLoginSection";
import constants from "../config/constants";

import cookie from "react-cookies";

var sessionData = {
  cityId: 1,
  cityName: "Mumbai",
  cityUniqueLink: "mumbai",
  isEnquiryCreated: false,
};
if (document.getElementById("sessionData") != null) {
  sessionData = JSON.parse(document.getElementById("sessionData").value);
}
var areaList = document.getElementById("areaList").value;
var occasionList = document.getElementById("occasionList").value;
var cityList = document.getElementById("cityList").value;
var environment = "production";
if (environment == "development") {
  var API_BASE = "https://apidemo.bookeventz.com/";
  var WEB_BASE = "https://staging.bookeventz.com/";
  window.WEB_BASE = "https://staging.bookeventz.com/";
} else {
  var API_BASE = "https://api.bookeventz.com/";
  var WEB_BASE = "https://www.bookeventz.com/";
  window.WEB_BASE = "https://www.bookeventz.com/";
}
window.loginPopup = function (isShowLoginModal) {
  var city = pathArray[2];
  var props = {
    config: config,
    constants: constants,
    sessionData: sessionData,
    citySelected: {city},
    pageType: "homePage",
    callType: "login",
    isShowLoginModal: isShowLoginModal,
  };
  myReactDOM12.unmountComponentAtNode(document.getElementById("login"));
  myReactDOM12.render(
    myReact12.createElement(HeaderLoginSection, props),
    document.getElementById("login")
  );
};
var OneSignal = window.OneSignal || [];
OneSignal.push(function () {
  OneSignal.init({
    appId: "f16c9026-ddf6-4f6c-935b-e39ac4b90c0f",
    autoRegister: false,
    autoResubscribe: true,
    notifyButton: {
      enable: false,
      position: "bottom-left",
    },
    promptOptions: {
      actionMessage: "Need updates about new venues and ideas for your event.",
      acceptButtonText: "YES",
      cancelButtonText: "NO THANKS",
    },
  });
});
$(document).ready(function () {
  setTimeout(function () {
    var bLazy = new Blazy();
  }, 3000);
  window.recontructBlazy = function () {
    if (typeof bLazy === "undefined") {
      var bLazy = new Blazy();
    } else {
      bLazy.destroy();
      bLazy = new Blazy();
    }
  };
});

var NewsessionData = cookie.load("sessionData");

console.log(NewsessionData, "NewsessionData");

if (NewsessionData == undefined) {
  NewsessionData = sessionData;
}

var url = window.location.href;

var pathname = new URL(url).pathname;
var pathArray = pathname.split("/");

if (pathArray[1] != "") {
  sessionData.CityUniqueLink = "mumbai";
  sessionData.CityName = "Mumbai";
  console.log(sessionData, "sessionData");
  NewsessionData = sessionData;
}

const object3 = {...NewsessionData};

render(
  <Landing404App
    cityByIp={document.getElementById("cityByIp").value}
    props={config}
    cityList={JSON.parse(cityList)}
    areaList={JSON.parse(areaList)}
    occasionList={JSON.parse(occasionList)}
    constants={constants}
    sessionData={object3}
    citySelected={pathArray[1]}
    pageType="404Page"
  />,
  document.getElementById("root")
);
