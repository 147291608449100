import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MobileHeader from "./Headers/mobileHeader";
import DesktopHeader from "./Headers/desktopHeader";
const NewHeader = ({ props, logoutNew, toggleLoginModalNew, sessionData, pageType, vendorData }) => {
  const [nearAreas, setNearAreas] = useState(null);
  const [cityArea, setCityArea] = useState("");
  const [sepcial, setSpecial] = useState(null)
  const getAreaLatLong = () => {
    let areaList = props?.areaList;
    // Define the maximum distance in kilometers for considering areas as nearby
    const MAX_DISTANCE_KM = 5; // Adjust this value as needed
    // Function to calculate the distance between two points using Haversine formula
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const R = 6371; // Radius of the earth in kilometers
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    };

    const deg2rad = (deg) => {
      return deg * (Math.PI / 180);
    };
    let areaId = props?.filters?.area !== "" && props.filters?.area;
    let latlong = props?.areaList.filter((area, index) => {
      if (areaId == area.AreaId) {
        return area;
      }
    });
    if (latlong) {
      // Filter out nearby areas based on the maximum distance threshold
      const nearbyAreas = areaList.filter((area) => {
        const distance = calculateDistance(latlong[0]?.Latitude, latlong[0]?.Longitude, area.Latitude, area.Longitude);
        return distance < MAX_DISTANCE_KM;
      });
      let notSameAreas = nearbyAreas?.filter((area, index) => {
        if (latlong[0].AreaId !== area.AreaId) {
          return area;
        }
      });
      setNearAreas(nearbyAreas.slice(0, 5));
    }
  };
  useEffect(() => {
    getAreaLatLong();
    getCategoryById()
  }, [props]);
  const capitalizeFirstAndLast = (word) => {
    if (word.length === 0) return ""; // Handle empty string
    // Convert the word to an array of characters
    let chars = word.split("");
    // Capitalize the first character
    chars[0] = chars[0].toUpperCase();
    // Capitalize the last character
    // chars[chars.length - 1] = chars[chars.length - 1].toUpperCase();
    // Join the characters back into a string
    return chars.join("");
  };
  useEffect(() => {
    let name = "";
    if (props?.filters && props?.filters.area) {
      props?.areaList?.forEach(function (row) {
        if (props?.filters.area && row.AreaId == props?.filters.area) {
          name = `${sessionData?.CityUniqueLink}/${row.AreaUniqueLink}`;
          setCityArea(name);
        }
      });
    } else if (props?.filters && props?.filters.parentAreaId) {
      name = `${sessionData?.CityUniqueLink}/${parentName.toLowerCase()}`;
      setCityArea(name);
    } else {
      name = `${sessionData?.CityUniqueLink}`;
      setCityArea(name);
    }
  }, [props]);
  const categories = [
    { id: 10, name: "Farmhouses", "link": "farmhouses" },
    { id: 14, name: "Palaces", "link": "palaces" },
    { id: 5, name: "Coworking Spaces", "link": "coworking-spaces" },
    { id: 6, name: "Penthouse", "link": "penthouse" },
    { id: 17, name: "Wedding Chapels", "link": "wedding-chapels" },
  ];
  const categoryIds = {
    Farmhouses: [4, 5, 6],
    Palaces: [9, 13, 33],
    "Coworking Spaces": [1],
    Penthouse: [60],
    "Wedding Chapels": [8]
  };
  const getCategoryById = () => {
    let searchId = parseInt(props?.sessionData?.CityId);
    let result = [];
    Object.entries(categoryIds).forEach(([categoryName, ids]) => {
      if (ids.includes(searchId)) {
        const category = categories.find(cat => cat.name === categoryName);
        if (category) {
          result.push(category);
        }
      }
    });

    setSpecial(result)
  };
  return (
    <>
      <DesktopHeader props={props} logoutNew={logoutNew} toggleLoginModalNew={toggleLoginModalNew} sessionData={sessionData} vendorData={vendorData} nearAreas={nearAreas} cityArea={cityArea} sepcial={sepcial} />
      {pageType !== null && pageType !== undefined && pageType !== "venueProduct" && (
        <MobileHeader props={props} logoutNew={logoutNew} toggleLoginModalNew={toggleLoginModalNew} sessionData={sessionData} vendorData={vendorData} nearAreas={nearAreas} cityArea={cityArea} sepcial={sepcial} />
      )}
    </>
  );
};
export default NewHeader;
