var React = require("react");
var axios = require("axios");
// var generalUtil = require('../views/general/generalUtil');
import generalUtil from "../views/general/generalUtil";
// var ContentJS = require("./HomePage/citiesSection");
import ContentJS from "./HomePage/citiesSection";
// var Header = require("./general/Header");
import Header from "./general/Header";
// var HomeFilter = require("./HomePage/HomeFIlter");
// import HomeFilter from './HomePage/HomeFIlter'
// var FinestVenueSection = require('./HomePage/finestVenueSection');
// import FinestVenueSection from './HomePage/finestVenueSection'
// var Footer = require("./general/Footer");
// import Footer from './general/Footer'

if (typeof window === "undefined") {
  global.window = {};
}
var environment = "production";
if (environment == "development") {
  var API_BASE = "https://apidemo.bookeventz.com/";
  var WEB_BASE = "https://staging.bookeventz.com/";
} else {
  var API_BASE = "https://api.bookeventz.com/";
  var WEB_BASE = "https://www.bookeventz.com/";
}
class Landing404App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherProps: {
        cityList: [],
        areaList: [],
        occasionList: [],
      },
      newcitySession: this.props.sessionData,
      sessionData: this.props.sessionData,
      cityList: this.props.cityList,
      areaList: this.props.areaList,
      occasionList: this.props.occasionList,
    };
  }
  initialilzeoneSignal() {
    OneSignal.push(function () {
      OneSignal.showHttpPrompt();
    });
  }
  checkforsubscribtion() {
    var that = this;
    setTimeout(function () {
      if (typeof OneSignal != "undefined") {
        OneSignal.push(function () {
          OneSignal.on("popoverAllowClick", function (permissionChange) {
            OneSignal.sendTags(
              {
                city: that.state.newcitySession.CityName,
                pageUrl: "homePage",
              },
              function (tagsSent) {
                // Callback called when tags have finished sending
              }
            );
          });
        });
      }
    }, 5000);
  }
  componentDidMount() {
    var that = this;
    var OneSignal = [];
    var cityId = "";
    OneSignal.push(function () {
      OneSignal.init({
        appId: "f16c9026-ddf6-4f6c-935b-e39ac4b90c0f",
        autoRegister: false,
        autoResubscribe: true,
        notifyButton: {
          enable: false,
          position: "bottom-left",
        },
        promptOptions: {
          actionMessage: "Need updates about new venues and ideas for your event.",
          acceptButtonText: "YES",
          cancelButtonText: "NO THANKS",
        },
      });
    });
    setTimeout(function () {
      {
        that.initialilzeoneSignal();
        that.checkforsubscribtion();
      }
    }, 5000);

    axios
      .get(this.props.props.BOOK_EVENTZ_API + "Banquets/getTotalVenueArtistCount", {
        params: {
          bypass: "404",
        },
      })
      .then((xhrResponse) => {
        if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
          let data = xhrResponse.data;
          this.setState({
            totalVenueServices: data.venueArtistCount,
            totalBanquetEnquiry: data.banquetEnquiryCount,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    var that = this;
    return (
      <div className="App homeView HomeViewNew">
        <div id="header">
          <Header
            props={this.props}
            otherProps={this.state}
            newcitySession={this.props.sessionData}
            sessionData={this.props.sessionData}
            areaList={this.props.areaList}
            pageType={this.props.pageType}
            cityList={this.state.cityList}
          />
        </div>
        <section className="container-fluid">
          <div className="row m0">
            <div className="bgDes hidden-xs hidden-sm"></div>
            <div className="bgMob hidden-lg hidden-md"></div>
            <div className="textWrap">
              <div className="error">ERROR</div>
              <div>Oooops.. Something went wrong</div>
              <div className="pnf">PAGE NOT FOUND</div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
// module.exports = Landing404App;
export default Landing404App;
